<template>
<div :class="$style.root">
  <VProgressLinear v-if="loading" absolute indeterminate color="primary darken-2" />
  <div :class="$style.row">
    <div v-for="({ id, temp, date, file, value, min, ...item }) in items" :class="$style.col" :key="id || temp">
      <FileIcon v-bind="{...file}" :readonly="readonly || item.readonly" @delete="onDelete({ id, temp, file })">
        <div v-if="showCreateDate && file && file.date" :class="[$style.date, $style.readonly]">
          <small>{{ file.date.slice(0, 10) || '__.__.____' }}</small>
        </div>
        <DateInput v-else-if="dated || item.dated" :min=min :value="date" v-slot="{ on, formatted, ISOString }" @input="(date) => onInput({id, temp, value, date })">
          <div v-if="readonly || item.readonly" :class="[$style.date, $style.readonly, {[$style.error]: (!formatted && (needDated || item.needDated)) || (min && (ISOString < min))}]">
            <small>{{ formatted || '__.__.____' }}</small>
            <span v-if="!formatted && (needDated || item.needDated)" :class="$style.tooltip">Дата обязательна</span>
            <span v-else-if="min && (ISOString < min)" :class="$style.tooltip">Дата истекла</span>
          </div>
          <div v-else :class="[$style.date, {[$style.error]: (!formatted && (needDated || item.needDated)) || (min && (ISOString < min))}]">
            <small v-on="on">{{ formatted || '__.__.____' }}</small>
            <span v-if="!formatted && (needDated || item.needDated)" :class="$style.tooltip">Заполните дату</span>
            <span v-else-if="min && (ISOString < min)" :class="$style.tooltip">Дата действия истекла</span>
          </div>
        </DateInput>
      </FileIcon>
    </div>
    <div v-if="!readonly && !filled" :class="$style.col">
      <label>
        <div :class="$style.input">
          <VFileInput v-model="temp" :rules="checker" ref="input" :multiple="multiple" :prepend-icon="null" hide-details="auto" />
          <FileIcon icon="mdi-file-plus-outline">
            <template #name>
              {{ addFileLabel }} <span class="error--text" v-if="required">*</span>
            </template>
          </FileIcon>
        </div>
      </label>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, map, forEach, compact, reject, uniqueId, head, find, isArray, toString } from 'lodash-es';
import FileIcon from '@/components/general/FileIcon/FileIcon';
import DateInput from '@/components/general/DateInput/DateInput';
export default {
  name: 'FileFieldMultiple',
  components: {
    FileIcon,
    DateInput,
  },
  props: {
    name: { type: String, default: 'doc[]'},
    required: { type: Boolean },
    dated: { type: Boolean, default: false },
    needDated: { type: Boolean, default: false },
    showCreateDate: { type: Boolean, default: false },
    value: { type: Array },
    filled: { type: Boolean },
    readonly: { type: Boolean },
    multiple: { type: Boolean, default: true },
    addFileLabel: { type: String, default: 'Добавить файл' },
    entity: { type: String },
    section: { type: String },
  },
  data() {
    return {
      temp: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      files: 'file/items',
    }),
    items() {
      return map(reject(this.value, { value: '' }), item => ({
        ...item,
        file: get(this.files, item.value),
      }))
    },
    values() {
      return compact(map(this.value, 'value'));
    },
    checker() {
      return this.required ? [
        () => !!find(this.value, ({ value }) => value) || 'Обязательное поле',
      ]: []
    },
  },
  methods: {
    ...mapActions({
      addToQueue: 'file/addToQueue',
      uploadList: 'file/uploadList',
      deleteItem: 'file/deleteItem',
    }),
    onInput({ id, temp, date, value }) {
      this.$emit('input', map(this.value, (item) => {
        if (id && item.id === id) return { ...item, date, value }
        if (temp && item.temp === temp) return { ...item, date, value }
        return item;
      }));
    },
    onDelete({ id, temp, file }) {
      if (id) this.$emit('input', reject(this.value, { id }));
      else if (temp) this.$emit('input', reject(this.value, { temp }));
      else if (file.id) this.$emit('input', reject(this.value, { value: file.id }));
    },
  },
  watch: {
    temp: {
      handler(value) {
        const files = isArray(value) ? value : [value];
        if (!files || !files.length || !this.temp) return;
        const data = new FormData();
        forEach(files, (file) => data.append(this.name, file))
        this.loading = true;
        const { section, entity } = get(this.$route, ['meta', 'info']);
        this.uploadList({files: data, section: this.section || section, entity: this.entity || entity }).then((items) => {
          const stub = JSON.parse(JSON.stringify(head(this.value) || {}));
          this.$emit('input', [
            ...this.value,
            ...map(items, ({ id }) => ({...stub, ...{
                id: '',
                temp: uniqueId('file-'),
                date: '',
                value: id,
              }})),
          ]);
          this.temp = null;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    values: {
      immediate: true,
      handler(values) {
        const { section, entity } = get(this.$route, ['meta', 'info']);
        forEach(values, (id) => {
          id && toString(id) !== '0' && !this.files[id] && this.addToQueue({ id, section: this.section || section, entity: this.entity || entity });
        });
      }
    }
  },
}
</script>

<style module lang="scss">
.root {
  position: relative;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  .input {
    display: grid;
    > div {
      grid-area: 1 / 1 / 2 / 2;
    }
    :global(.error--text) ~ div fieldset {
      border-width: 2px !important;
      border-color: #ff5252 !important;
    }
    :global(.v-input) {
      pointer-events: none;
      margin: 0;
      padding: 0;
      :global(.v-input__slot) {
        opacity: 0;
        height: 40px;
      }
    }
  }
  .date {
    border: 1px solid rgba(0, 0, 0, 0.38);
    top: 0;
    padding: 1px 5px;
    position: absolute;
    margin-top: -5px;
    width: 76px;

    &.readonly {
      border: 1px dashed rgba(0, 0, 0, 0.1);
    }
    &.error {
      color: #ff5252;
      border: 1px solid #ff5252;
    }
  }
  .tooltip {
    position: absolute;
    left: -1px;
    bottom: 100%;
    background: #ff5252;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    padding: 4px 8px;
    z-index: 1;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    .col {
      padding: 5px;
      width: 210px;
      box-sizing: border-box;
    }
  }
}
</style>
